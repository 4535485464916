
.alice-carousel__stage {
  margin-bottom: 10px;
}

.alice-carousel__stage-item {
  padding: 0px 10px;
}

.alice-carousel__dots-item:not(.__custom).__active {
  background-color: #00684a;
}

.alice-carousel__dots-item:not(.__custom) {
  background-color: #E8EDEB ;
}

.alice-carousel__prev-btn-item {
  color: #00684a;
}

.alice-carousel__next-btn-item {
  color: #00684a;
}